main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}